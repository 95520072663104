// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-interviews-jsx": () => import("./../../../src/pages/interviews.jsx" /* webpackChunkName: "component---src-pages-interviews-jsx" */),
  "component---src-pages-zur-reihe-jsx": () => import("./../../../src/pages/zur-reihe.jsx" /* webpackChunkName: "component---src-pages-zur-reihe-jsx" */),
  "component---src-templates-home-page-jsx": () => import("./../../../src/templates/HomePage.jsx" /* webpackChunkName: "component---src-templates-home-page-jsx" */),
  "component---src-templates-interview-jsx": () => import("./../../../src/templates/Interview.jsx" /* webpackChunkName: "component---src-templates-interview-jsx" */)
}

